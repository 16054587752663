import * as React from "react";
import { Component, Fragment, useState } from "react";
import * as PropTypes from "prop-types";
import * as algoliasearch from "algoliasearch/lite";
import {
  Configure,
  connectStateResults,
  Hits,
  InstantSearch,
  SearchBox
} from "react-instantsearch-dom";
import { FunctionComponent } from "react";
import {
  Button,
  Link,
  Heading,
  PageSidebar,
  PageSection,
  Paragraph,
  Sidebar
} from "../components";
import "./FindYourCommunity.css";
import externalLinkIcon from "../images/property-website-link-icon.svg";
import { graphql } from "gatsby";

const SEARCH_RESULTS_CAP = 1000;

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

interface CommunitySearchHit {
  "Address 1": string;
  "Address 2": string;
  "Building Name": string;
  City: string;
  ME: string;
  ME_Email: string;
  ME_PhoneNo: string;
  RM: string;
  RM_Email: string;
  RM_Phone: string;
  State: string;
  Website_URL: string;
  Zip: string;
  eStatements: string;
}

interface HitResultProps {
  hit: CommunitySearchHit;
}

interface HitResultState {
  showDetails: boolean;
}

function hasEStatements(algolia_hit: CommunitySearchHit) {
  return /true/i.test(algolia_hit.eStatements);
}

class HitResult extends Component<HitResultProps, HitResultState> {
  public state: Readonly<HitResultState> = {
    showDetails: false
  };

  private toggleDetails: () => void = () => {
    this.setState({
      showDetails: !this.state.showDetails
    });
  };

  public render(): JSX.Element {
    const isEStatementsAvailable = hasEStatements(this.props.hit);

    return (
      <article className="hit-Result">
        <section className="address">
          <Heading level={5}>{this.props.hit["Building Name"]}</Heading>
          <p>{this.props.hit["Address 1"]}</p>
          {this.props.hit["Address 2"] && <p>{this.props.hit["Address 2"]}</p>}
          <p>
            {this.props.hit["City"] && `${this.props.hit["City"]},`}{" "}
            {this.props.hit["State"]} {this.props.hit["Zip"]}
          </p>
        </section>

        <div className="button-container">
          <Button onClick={this.toggleDetails}>
            {this.state.showDetails ? "Less Info" : "More Info"}
          </Button>
          {isEStatementsAvailable && (
            <Button
              ghost
              href="https://docsight.net/xdsweb/OENLogon.aspx?ID=3105"
            >
              Access eStatements{" "}
              <img src={require("../images/right-arrow-icon.svg")} />
            </Button>
          )}
        </div>

        {this.state.showDetails && (
          <div className="hit-Details">
            <hr />
            {this.props.hit.Website_URL && (
              <section>
                <Heading level={6}>Website</Heading>
                <p>
                  <Link
                    className="link__property-website"
                    href={this.props.hit.Website_URL}
                    title={`Go to ${this.props.hit["Building Name"]}'s website`}
                    target="_blank"
                  >
                    {this.props.hit.Website_URL}&nbsp;
                    <img
                      src={externalLinkIcon}
                      aria-hidden="true"
                      alt="External link"
                    />
                  </Link>
                </p>
              </section>
            )}
            {(this.props.hit.ME ||
              this.props.hit.ME_Email ||
              this.props.hit.ME_PhoneNo) && (
              <section>
                <Heading level={6}>Management Executive</Heading>
                <p>
                  {this.props.hit.ME && (
                    <Fragment>
                      {this.props.hit.ME}
                      <br />
                    </Fragment>
                  )}
                  {this.props.hit.ME_Email && (
                    <Fragment>
                      <a
                        href={`mailto:${this.props.hit.ME_Email}`}
                        className="hit-email"
                      >
                        {this.props.hit.ME_Email}
                      </a>
                      <br />
                    </Fragment>
                  )}
                  {this.props.hit.ME_PhoneNo && (
                    <Fragment>
                      {this.props.hit.ME_PhoneNo}
                      <br />
                    </Fragment>
                  )}
                </p>
              </section>
            )}
            {(this.props.hit.RM ||
              this.props.hit.RM_Email ||
              this.props.hit.RM_Phone) && (
              <section>
                <Heading level={6}>On-site Manager</Heading>
                <p>
                  {this.props.hit.RM && (
                    <Fragment>
                      {this.props.hit.RM}
                      <br />
                    </Fragment>
                  )}
                  {this.props.hit.RM_Email && (
                    <Fragment>
                      <a
                        href={`mailto:${this.props.hit.RM_Email}`}
                        className="hit-email"
                      >
                        {this.props.hit.RM_Email}
                      </a>
                      <br />
                    </Fragment>
                  )}
                  {this.props.hit.RM_Phone && (
                    <Fragment>
                      {this.props.hit.RM_Phone}
                      <br />
                    </Fragment>
                  )}
                </p>
              </section>
            )}
          </div>
        )}
      </article>
    );
  }
}

const SearchResults = connectStateResults(props => {
  if (
    props.searchState.query &&
    props.searchState.query.trim() &&
    props.searchResults
  ) {
    return (
      <Fragment>
        <Heading level={2}>
          {props.searchResults.nbHits <= SEARCH_RESULTS_CAP
            ? props.searchResults.nbHits
            : SEARCH_RESULTS_CAP}{" "}
          Results
        </Heading>
        <hr />
        <Configure hitsPerPage={SEARCH_RESULTS_CAP} />
        <Hits hitComponent={HitResult} />
      </Fragment>
    );
  }
  return null;
});

const FindYourCommunity: FunctionComponent = (props): JSX.Element => {
  //@ts-ignore
  const urlParams = new URLSearchParams(props.location.search);
  const [searchState, setSearchState] = useState({});

  const { sidebarImage } = props.data;

  const onSearchStateChange = searchState => {
    setSearchState(searchState);
  };

  if (!searchState.hasOwnProperty("query") && urlParams.has("query")) {
    setSearchState({ query: urlParams.get("query") });
  }

  return (
    <PageSidebar
      title="Find a Community"
      layout="right-sidebar"
      sidebar={
        <Sidebar>
          <img
            className="find-your-community--sidebar-img"
            src={sidebarImage.file.url}
            alt={sidebarImage.description}
          />
        </Sidebar>
      }
    >
      <PageSection className="find-community-wrapper">
        <Heading>Your Community</Heading>
        <Paragraph>
          Look up community information and contact details for your
          community&apos;s management staff
        </Paragraph>
        <Paragraph>
          <InstantSearch
            indexName="Community Lookup"
            searchClient={searchClient}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
          >
            <SearchBox
              autoFocus
              searchAsYouType={false}
              submit="Search"
              translations={{
                placeholder: "Property Name or Address"
              }}
            />
            <SearchResults />
          </InstantSearch>
        </Paragraph>
      </PageSection>
    </PageSidebar>
  );
};

FindYourCommunity.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
};

export const pageQuery = graphql`
  {
    sidebarImage: contentfulAsset(title: { eq: "Find a Community > Sidebar" }) {
      description
      file {
        url
      }
    }
  }
`;

export default FindYourCommunity;
